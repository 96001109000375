function Header() {
  return (
    <div className="row">
      <div className="col-sm-2 text-center logo">
        <img
          src="/images/ecwa_logo_only.png"
          className="img-fluid"
          alt="ECWA logo"
          style={{ width: 200, height: 200 }}
        />
      </div>
      <div className="col-sm-9 font-weight-bold text-center align-self-center header">
        <span style={{ fontSize: "3em" }}>ECWA </span>
        <span className="break-line">COLLEGE OF HEALTH TECHNOLOGY, KAGORO</span>
      </div>
    </div>
  );
}

export default Header;
