import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux'

import { LoginType, loginHasErrorClass, apiUrl } from "../utils/Common";
import { addUser, addPaymentStatus } from "../../states/reducers/userReducer"
const axios = require("axios");
function Login(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const initialData = {
    username: "",
    password: "",
  };
  const initialErrors = {
    username: "",
    password: "",
  };
  const [errors, setErrors] = useState(initialErrors);
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const type = props.type;
  const inputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const loginUser = (e) => {
    e.preventDefault();
    setLoading(true);
    const formBody = JSON.stringify(data);

    axios
      .post(`${apiUrl}/${type.toLowerCase()}/login/`, formBody, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        //Hide Loader
        setLoading(false);
        const { success, data, message } = response.data;
        if (!success) {
          toast.error(message);
        } else {
          const { account, paymentStatus } = data;
          switch (type) {
            case LoginType.ADMISSION:
              dispatch(addUser(account));
              dispatch(addPaymentStatus(paymentStatus));
              break;
            case LoginType.STUDENT:
              dispatch(addUser(account));
              break;
            default:
              navigate("/");
              break;
          }
          navigate("/" + type.toLowerCase());
        }
      })
      .catch((error) => {
        //Hide Loader
        setLoading(false);
        console.log(error);
        toast.error("Cannot Login. Please check your Internet connection");
      });
  };

  const getUsernameLabel = () => {
    if (type === LoginType.ADMISSION) {
      return "Registration Number";
    } else if (type === LoginType.ADMIN) {
      return "Username";
    } else {
      return "Matric Number";
    }
  };
  return (
    <form onSubmit={loginUser} className="login row justify-content-center bg-primary">
      <h3 className="text-light py-3 text-center">Login</h3>
      <div className="col-md-6 mb-5">
        <div className="bg-white shadow-lg px-5 py-5 roundBorder mx-4">
          <div className="row justify-content-center bg-light mt-4 mx-0 py-5 loginBox">
            <div className="col-sm-11 input-field">
              <label className="inputText">{getUsernameLabel()}</label>
              <input
                className={loginHasErrorClass(errors.username)}
                type="text"
                name="username"
                placeholder=""
                autoComplete="username"
                required
                onChange={(e) => inputChange(e)}
              />
              <label className="inputText mt-4">Password</label>
              <input
                className={loginHasErrorClass(errors.password)}
                type="password"
                name="password"
                autoComplete="current-password"
                required
                onChange={(e) => inputChange(e)}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-6 col-lg-6 mb-1">
              <input className=" text-left mb-4 text-primary" type="checkbox" />
              <span className="mx-2">Remember me</span>
            </div>
            <div className="col-sm-6 col-lg-6 mb-1 text-end">
              <Link to="/auth/forgot-password">Forgot Password?</Link>
            </div>
          </div>

          <div className="col-sm-12 text-center my-4 pb-3">
            <button
              className="form-control fw-bold shadow-none py-3 btn btn-primary btn-md btn-block"
              name="login"
              type="submit"
              disabled={loading}>
              {loading ? (
                <span>
                  <Spinner animation="border" size="sm" variant="white" /> Login . . .
                </span>
              ) : (
                <>
                  <span className="text-light">Login</span>
                  <i className="bi bi-chevron-right text-light m-2"></i>
                </>
              )}
            </button>
          </div>
          {type === LoginType.ADMISSION && (
            <div className="row mb-3">
              <div className="col-sm-12 mb-1 text-center">
                <span className="mx-2">Don't have an account?</span>
              </div>
              <div className="col-sm-12 text-center text-primary">
                <Link to="/auth/register">Apply Now</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
}

export default Login;
