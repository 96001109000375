import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";

import { logOut } from "../states/reducers/userReducer";

const ProtectedRoute = ({ redirectPath = "/", children }) => {
  const dispatch = useDispatch();

  const AuthVerify = () => {
    const { user } = useSelector((state) => state.user);
    //const user = userStore.user;
    try {
      if (user) {
        const token = user.jwtToken;
        const { exp } = jwt_decode(token);
        if (Date.now() > exp * 1000) {
          dispatch(logOut());
        }
        return Date.now() <= exp * 1000;
      }
    } catch (ex) {
      console.log("Logged Out");
      dispatch(logOut());
    }

    return false;
  };
  if (!AuthVerify()) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
export default ProtectedRoute;
