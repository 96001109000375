import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "./index.css";

function Home() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-white mb-4">
          <div className="container-fluid">
            <div className="navbar-header">
              <img className="logo" src="/images/ECHTK-big.png" alt="" style={imageStyle} />
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>
        <main className="container-fluid hero">
          <div className="row" style={{ height: "100vh" }}>
            <div className="col-sm-6" style={{ background: "#f2fcf9" }}>
              <div className="row px-2 py-2">
                <div className="col-sm-8 mx-2 my-4">
                  <h5 className="d-inline-block mb-2 text-success">Welcome</h5>
                  <div className="row pt-4">
                    <div className="col-sm-12">
                      <ul>
                        <li>
                          <button
                            className="btn btn-link btn-lg">
                            {/* // onClick={() => setShowModal(true)}> */}
                            Apply for Admission
                          </button>
                        </li>
                        <li>
                          <Link className="btn btn-link btn-lg" to="/auth/login/admission">
                            Admission Login
                          </Link>
                        </li>
                        <li>
                          <Link className="btn btn-link btn-lg" to="/auth/login/student">
                            Student Login
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 px-0">
              <img className="image" src="/images/ictunit.jpeg" alt="" width="100%" />
            </div>
          </div>
        </main>
      </header>
      <Modal show={showModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <h4>Application Instructions</h4>
          <div id="notice_admission">
            <p>
              Before filling and submitting the online application form, Parent/Guardian must first
              create an account on the portal. To Create Account:
            </p>
            <ol>
              <li>Click on Start Application button</li>
              <li>Fill in your details.</li>
              <li>
                Make sure you supplied your <b>functional personal</b> email
              </li>
              <li>Click on Create Account</li>
              <li>An OTP will be sent to the supplied mail</li>
              <li>Enter the OTP to confirm your email and complete your registration</li>
              <li>Click on Login Button</li>
            </ol>
            <p>
              After Account Creation. You can then login to register your wards. Follow these steps:
            </p>
            <ol>
              <li>Click on Login Button</li>
              <li>Read the Payment Instructions on the Dashboard</li>
              <li>Make Payment for your ward(s)</li>
              <li>After successful payment, register your ward</li>
              <li>Finish registration by clicking "Submit Finally" button</li>
              <li>
                You can then print your Registration Slip and wait for further communication through
                your registered email
              </li>
            </ol>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary btn-lg col-sm-3" onClick={() => setShowModal(false)}>
            Close
          </button>
          <Link className="btn btn-primary btn-lg col-sm-3" to="/auth/register">
            Apply Now
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Home;

const imageStyle = {
  height: 60,
  width: 200,
};
