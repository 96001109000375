import React from "react";
import { useState, useEffect } from "react";

const Timer = ({ durationInSeconds, timer }) => {
    const [hours, setHours] = useState(Math.floor(durationInSeconds / 3600));
    const [minutes, setMinutes] = useState(
        Math.floor((durationInSeconds % 3600) / 60)
    );
    const [seconds, setSeconds] = useState(
        Math.floor((durationInSeconds % 3600) % 60)
    );

    const setTime = () => {
        durationInSeconds -= 1;
        if (durationInSeconds >= 0) {
            setHours(Math.floor(durationInSeconds / 3600));
            setMinutes(Math.floor((durationInSeconds % 3600) / 60));
            setSeconds(Math.floor((durationInSeconds % 3600) % 60));

            timer(durationInSeconds);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => setTime(), 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="captionlabel" style={{ fontSize: "1.5em" }}>
            <i className="bi bi-clock"></i>
            <label className="ms-2">Remaining Time</label>
            <label
                className={`${hours === 0 && minutes === 0 && seconds < 60
                    ? "text-danger"
                    : "text-success"
                    } ms-2`}>
                {`${hours > 0 ? hours.toString() + "h " : ""}${minutes > 0 ? minutes.toString() + "m " : ""
                    }${seconds.toString() + "s"}`}
            </label>
        </div>
    );
};

export default Timer;
