import { Route, Routes } from "react-router-dom";
import AdmissionIndex from "./components/admission/Index";
import StudentIndex from "./components/student/Index";
import AuthIndex from "./components/auth/Index";
import HomeIndex from "./components/home/Index";
import NotFound from "./components/NotFound";
import ProtectedRoute from "./components/ProtectedRoute";
import { ToastContainer, Bounce } from "react-toastify";

const App = () => {
  return (
    <div className="container-fluid">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeButton={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Bounce}
      />
      <Routes>
        <Route path="/auth/*" element={<AuthIndex />} />
        <Route
          path="/admission/*"
          element={
            <ProtectedRoute redirectPath="/auth/login/admission">
              <AdmissionIndex />
            </ProtectedRoute>
          }
        />
        <Route
          path="/student/*"
          element={
            <ProtectedRoute redirectPath="/auth/login/student">
              <StudentIndex />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<HomeIndex />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
