import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    examId: null,
    title: null,
    questions: [],
    answers: [],
    remainingDurationInSeconds: 0,
    lastSyncTime: null,
    isResult: false,
};

const examSlice = createSlice({
    name: "exam",
    initialState,
    reducers: {
        //addQuestions: (state, action) => { state.questions = action.payload; },
        addAnswers: (state, action) => { state.answers = action.payload; },
        setRemainingDurationInSeconds: (state, action) => { state.remainingDurationInSeconds = action.payload; },
        setLastSyncTime: (state, action) => { state.lastSyncTime = action.payload; },
        setTitle: (state, action) => { state.title = action.payload; },
        setAll: (state, action) => {
            state.examId = action.payload.examId;
            state.remainingDurationInSeconds = action.payload.remainingDurationInSeconds;
            state.questions = action.payload.questions;
            state.title = action.title;
            state.answers = action.payload.answers;
            state.lastSyncTime = action.payload.lastSyncTime;
        },
        removeState: (state) => {
            state.examId = initialState.examId;
            state.remainingDurationInSeconds = initialState.remainingDurationInSeconds;
            state.questions = initialState.questions;
            state.answers = initialState.answers;
            state.title = initialState.title;
            state.lastSyncTime = initialState.lastSyncTime;
        }
    }
});

export const { addAnswers, setLastSyncTime, setRemainingDurationInSeconds, setTitle, setAll, removeState } = examSlice.actions;

export default examSlice.reducer;