import React from 'react';
import { ThreeCircles } from 'react-loader-spinner';

function LoadingPage({ loading = true, errorMessage, children }) {
    return loading ?
        <div className="d-flex justify-content-center align-items-center">
            <ThreeCircles
                height="100"
                width="100"
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
            />
        </div>
        : errorMessage ? <div className="alert alert-danger fade show text-center" role="alert">
            {errorMessage}
        </div> : children;
}

export default LoadingPage