import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    registeredCourses: null,
};

const courseSlice = createSlice({
    name: "course",
    initialState,
    reducers: {
        addRegisteredCourses: (state, action) => { state.registeredCourses = action.payload; }
    }
});

export const { addRegisteredCourses } = courseSlice.actions;

export default courseSlice.reducer;