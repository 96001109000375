import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Moment from "moment";

import { PostType, apiUrl, dateTimeFormat } from "../utils/Common";
import { addRegisteredCourses } from "../../states/reducers/courseReducer";
import LoadingPage from "../layouts/LoadingPage";

const axios = require("axios");

function Dashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [classwork, setClasswork] = useState(null);
    const [examInfo, setExamInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingClasswork, setLoadingClasswork] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [courseSelected, setCourseSelected] = useState("");
    const [postTypeSelected, setPostTypeSelected] = useState("");
    const [examModal, setExamModal] = useState({
        show: false,
        loadingInfo: false,
        loadingResult: false,
    });

    const { user } = useSelector(state => state.user);
    const { registeredCourses } = useSelector(state => state.course);

    useEffect(() => {
        if (registeredCourses && registeredCourses.length > 0) {
            setLoading(false);
        } else {
            axios
                .get(`${apiUrl}/student/registered-courses`, {
                    headers: {
                        Authorization: `Bearer ${user.jwtToken}`,
                    },
                })
                .then(response => {
                    const { success, data, message } = response.data;
                    console.log(response.data);
                    if (success) {
                        dispatch(addRegisteredCourses(data));
                        setLoading(false);
                    } else {
                        setErrorMessage(message);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    setErrorMessage("Error Loading. Please reload the page");
                    setLoading(false);
                });
        }
    }, [user.jwtToken, dispatch]);

    const filterPostType = (index, value) => { };

    const loadClasswork = value => {
        setCourseSelected(value);
        const course = registeredCourses.find(c => c.code === value);
        //return;
        if (course) {
            setLoadingClasswork(true);
            const { proCode, proType, code, levelCode, session, semester } = course;
            const postData = { proCode, proType, code, levelCode, session, semester };
            axios
                .post(`${apiUrl}/student/classwork`, postData, {
                    headers: {
                        Authorization: `Bearer ${user.jwtToken}`,
                    },
                })
                .then(response => {
                    const { success, data, message } = response.data;
                    console.log(response.data);
                    if (success) {
                        setClasswork(data);
                        setLoadingClasswork(false);
                    } else {
                        toast.error(message);
                        setLoadingClasswork(false);
                    }
                })
                .catch(error => {
                    toast.error(
                        "Cannot Load Classwork: Please check your Internet connection and try again"
                    );
                    setLoadingClasswork(false);
                });
        }
    };

    const loadExamInfo = (examId) => {
        setExamModal({ ...examModal, loadingInfo: true });
        axios
            .get(`${apiUrl}/student/exam/info/${examId}`, {
                headers: {
                    Authorization: `Bearer ${user.jwtToken}`,
                },
            })
            .then(response => {
                const { success, data, message } = response.data;
                if (success) {
                    setExamInfo(data);
                    setExamModal({ ...examModal, loadingInfo: false, show: true });
                } else {
                    toast.error(message);
                    setExamModal({ ...examModal, loadingInfo: false });
                }
            })
            .catch(() => {
                toast.error("There is an error loading Information");
                setExamModal({ ...examModal, loadingInfo: false });
            });
    };

    const PageContent = () => {
        return (
            registeredCourses && (
                <>
                    <div className="row">
                        <div className="col-sm-2">
                            <label className="captionlabel">Course</label>
                        </div>
                        <div className="col-sm-4">
                            <select
                                className="form-select"
                                name="courses"
                                id="courses"
                                defaultValue={courseSelected}
                                onChange={e => loadClasswork(e.target.value)}>
                                <option key="Select" value="" disabled>
                                    -- Select Course --
                                </option>
                                {registeredCourses &&
                                    registeredCourses.map(c => (
                                        <option
                                            key={c.code}
                                            value={c.code}>{`${c.code} - ${c.title}`}</option>
                                    ))}
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <label className="captionlabel">Type</label>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="postTypeOptions"
                                    id="AllRadio"
                                    value=""
                                    checked={postTypeSelected === ""}
                                    onChange={e => filterPostType(0, "")}
                                />
                                <label className="form-check-label" htmlFor="AllRadio">
                                    All
                                </label>
                            </div>
                            {PostType.map((pt, index) => {
                                return (
                                    <div key={pt.name} className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="postTypeOptions"
                                            id={`${pt.name}radio`}
                                            value={pt.value}
                                            checked={postTypeSelected === pt.value}
                                            onChange={e => filterPostType(index + 1, pt.value)}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={`${pt.name}radio`}>
                                            {pt.name}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div id="alert_container"></div>
                    {loadingClasswork && (
                        <div className="d-flex justify-content-center text-danger">
                            <Spinner animation="border" size="lg" variant="success" />{" "}
                            <span className="text-success">Loading Classwork . . .</span>
                        </div>
                    )}

                    <div className="table-responsive">
                        <table className="table d-md-table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Due Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {classwork &&
                                    classwork.map((c, index) => (
                                        <tr key={c.postId}>
                                            <td>{index + 1}</td>
                                            <td>{c.postTitle}</td>
                                            <td>{Moment(c.dueDate).format(dateTimeFormat)}</td>
                                            <td>
                                                {" "}
                                                <button
                                                    className="btn btn-success"
                                                    onClick={() => loadExamInfo(c.postId)}>
                                                    {examModal.loadingInfo ? (
                                                        <span>
                                                            <Spinner
                                                                animation="border"
                                                                size="sm"
                                                                variant="white"
                                                            />{" "}
                                                            Loading . . .
                                                        </span>
                                                    ) : (
                                                        <span>Take {c.type}</span>
                                                    )}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <Modal backdrop="static"
                        keyboard={false}
                        show={examModal.show}
                        onHide={() => setExamModal({ ...examModal, show: false })}
                        size="lg"
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h2 className="mt-2">
                                <span>Exam Details</span>
                            </h2>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="captionlabel">Instruction</label>
                                </div>
                                <div className="col-sm-9">
                                    {examInfo?.instruction}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="captionlabel">Exam Date</label>
                                </div>
                                <div className="col-sm-9">
                                    {`${Moment(examInfo?.startDate).format(dateTimeFormat)} to ${Moment(examInfo?.endDate).format(dateTimeFormat)}`}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-sm-3">
                                    <label className="captionlabel">Duration</label>
                                </div>
                                <div className="col-sm-3">
                                    {`${examInfo?.duration} minutes`}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-sm-3">
                                    <label className="captionlabel">Total Question</label>
                                </div>
                                <div className="col-sm-3">
                                    {examInfo?.totalQuestions}
                                </div>
                            </div>
                            <div className="row justify-content-center px-1">
                                <label id="ExamModalMessageLabel" />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setExamModal({ ...examModal, show: false })}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => navigate("../exam", { state: { examIdProp: examInfo.examId } })}>
                                Start Exam
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )
        );
    };
    return (
        <LoadingPage loading={loading} errorMessage={errorMessage}>
            <PageContent />
        </LoadingPage>
    );
}

export default Dashboard;
