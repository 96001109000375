import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from 'redux-persist';
import thunk from "redux-thunk";

import userReducer from "./reducers/userReducer";
import examReducer from "./reducers/examReducer";
import courseReducer from "./reducers/courseReducer";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
};
const appReducer = combineReducers({
    user: userReducer,
    exam: examReducer,
    course: courseReducer
})

const rootReducer = (state, action) => {
    if (action.type === 'user/logOut') {
        // this applies to all keys defined in persistConfig(s)
        storage.removeItem('persist:root');
        state = {};
    }
    return appReducer(state, action);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore(
    {
        reducer: persistedReducer,
        devTools: process.env.NODE_ENV !== 'production',
        middleware: [thunk]
    }
);
export const persistor = persistStore(store)

export default store;
