import React, { useEffect, useState } from 'react';
import { Alert, AlertService } from "@deltreetech/react-bootstrap-alert";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import LoadingPage from '../layouts/LoadingPage';
import { apiUrl, PaymentType, PaymentStatusType } from "../utils/Common";
import { addPaymentStatus } from "../../states/reducers/userReducer";

const axios = require("axios");

function PaymentResponse() {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const paymentReference = searchParams.get("reference");

    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        axios
            .get(`${apiUrl}/payment/verify/${paymentReference}`, {
                headers: {
                    Authorization: `Bearer ${user.jwtToken}`,
                },
            })
            .then((response) => {
                const { success, data, message } = response.data;
                if (success) {
                    if (data.status === PaymentStatusType.success) {
                        let successMessage = "Your Payment is Successful.";
                        if (data.paymentCategory.toUpperCase() === PaymentType.Entrance.toUpperCase()) {
                            successMessage += " <a href='registration' class='alert-link'>Click Here to Continue Registration</a>";
                            dispatch(addPaymentStatus({ admissionPaymentPaid: true }));
                        } else {
                            successMessage += " Your details has now been moved to the Student Portal. <a href='../dashboard' class='alert-link'>Click Here to Print Admission Letter</a>";
                        }
                        AlertService.success(
                            successMessage,
                            {
                                id: "alert-container",
                            }
                        );
                    } else {
                        AlertService.error(
                            `Payment Failed. Reason: ${data.gatewayResponse}. <a href='payment?reference=${paymentReference}' class='alert-link'>Click Here to Retry Payment</a>"`,
                            {
                                id: "alert-container",
                            }
                        );
                    }
                    setLoading(false);
                } else {
                    AlertService.error(
                        `${message} <a href='payment' class='alert-link'>Click Here to Make Payment</a>`,
                        {
                            id: "alert-container",
                        }
                    );
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error Confirming Payment Response", error);
                AlertService.error(
                    "Error Confirming Payment Response",
                    {
                        id: "alert-container",
                    }
                );
                setLoading(false);
            });
    }, [paymentReference, user.jwtToken, dispatch]);
    return (
        <LoadingPage loading={loading}><Alert id="alert-container" /></LoadingPage>
    )
}

export default PaymentResponse