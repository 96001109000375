import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

import LoadingPage from '../layouts/LoadingPage';
import { apiUrl, convenienceFee, formatAmount, paystackPublicKey } from "../utils/Common";
import { addPaymentStatus } from "../../states/reducers/userReducer";

const axios = require("axios");

function Payment() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [referenceLoading, setReferenceLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const { user } = useSelector((state) => state.user);
    const [searchParams] = useSearchParams();
    const paymentReference = searchParams.get("reference");
    const [paymentConfig, setPaymentConfig] = useState({
        reference: null,
        email: user.email,
        amount: 0,
        publicKey: paystackPublicKey,
    });

    useEffect(() => {
        const loadPaymentInfo = () => {
            axios
                .get(`${apiUrl}/payment/entrance/${paymentReference}`, {
                    headers: {
                        Authorization: `Bearer ${user.jwtToken}`,
                    },
                })
                .then((response) => {
                    if (response.data.success) {
                        setData(response.data.data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setErrorMessage(response.data.message);
                    }
                })
                .catch((error) => {
                    console.error("Error Fetching Payment Data!", error);
                    setLoading(false);
                    setErrorMessage('Error Fetching Payment Data!');
                });
        }
        loadPaymentInfo()
    }, [paymentReference, user.jwtToken]);

    let initializePayment = usePaystackPayment(paymentConfig);

    const onPaymentSuccess = (result) => {
        setPaymentConfig({ ...paymentConfig, reference: null });
        if (result.status === "success") {
            console.log("Payment Succeed");
            navigate(`../payment-response?reference=${result.reference}`);
        }
    };

    const onPaymentClose = () => {
        setPaymentConfig({ ...paymentConfig, reference: null });
        console.log("closed");
    };

    if (paymentConfig.reference != null) initializePayment(onPaymentSuccess, onPaymentClose);

    const makePayment = () => {
        setPaymentConfig({
            ...paymentConfig,
            reference: paymentReference,
            amount: (data.scheduledFee.amount + convenienceFee) * 100,
        });
    };

    const generateNewPaymentReference = () => {
        setReferenceLoading(true);
        axios
            .get(`${apiUrl}/payment/reference/${paymentReference}`, {
                headers: {
                    Authorization: `Bearer ${user.jwtToken}`,
                },
            })
            .then((response) => {
                const { success, data, message } = response.data;
                if (success) {
                    const newReference = data.newReference;
                    dispatch(addPaymentStatus({ admissionPaymentReference: newReference }));
                    setReferenceLoading(false);
                    setPaymentConfig({ ...paymentConfig, reference: null });
                    navigate(`payment?reference=${newReference}`);
                } else {
                    setReferenceLoading(false);
                    toast.error(message);
                }
            })
            .catch((error) => {
                console.error("Error Fetching Payment Data!", error);
                setReferenceLoading(false);
                toast.error("There's an error generating new Payment Reference");
            });
    }

    const PageContent = () => {
        return <div>
            <h3 className="mt-3">
                <span>For you to continue your Application you have to make Payment Online</span>
            </h3>
            <div className="row mx-3 captionlabel text-primary">
                <div className="col-sm-12 p-1">
                    <label>Current Information</label>
                </div>
            </div>
            <div className="row ml-5 mb-5">
                <div className="col-sm-2">
                    <label className="captionlabel">Payment Session</label>
                </div>
                <div className="col-sm-4">
                    <label id="CurrentSessionLabel">{data.session}</label>
                </div>
                <div className="col-sm-2">
                    <label className="captionlabel">Applicant Name</label>
                </div>
                <div className="col-sm-4">
                    {`${user.firstName} ${user.lastName}`}
                </div>
            </div>
            <div className="row justify-content-center px-2">
                <label id="MessageLabel" text="" />
            </div>
            <div id="DetailsPanel">
                <div className="alert alert-info text-center p-0 pt-2">
                    <div>
                        <b style={{ color: "Red" }}>IMPORTANT NOTICE: </b>
                    </div>
                    <ul className="list-unstyled p-0">
                        <li>Please Click on the <b>Retry</b> button if you have a pending transaction.</li>
                        <li>Click on <b>New Ref</b> button to create a new transaction reference. Do not do this (to avoid double payment) unless you are sure the former payment is not successful</li>
                    </ul>
                </div>
                <div className="row mx-3 captionlabel text-primary">
                    <div className="col-sm-12 p-1">
                        <label>Payment Information</label>
                    </div>
                </div>
                <div className="row ml-5 mb-5">
                    <div className="col-sm-2">
                        <label className="captionlabel">Transaction Ref</label>
                    </div>
                    <div className="col-sm-4">
                        <label className='me-2'>{paymentReference}</label>
                        <button className="btn btn-sm btn-success btn-responsive me-2"
                            onClick={() => navigate(`../payment-response?reference=${paymentReference}`)}>Retry</button>
                        <button className="btn btn-sm btn-danger btn-responsive"
                            onClick={() => generateNewPaymentReference()}
                            disabled={referenceLoading}>
                            {referenceLoading ? (
                                <Spinner animation="border" size="sm" variant="white" />
                            ) : (
                                <span>New Reference</span>
                            )}
                        </button>
                    </div>
                    <div className="col-sm-2">
                        <label className="captionlabel">Payment Type</label>
                    </div>
                    <div className="col-sm-4">
                        {data.scheduledFee.description}
                    </div>
                    <div className="col-sm-2">
                        <label className="captionlabel">Payment Amount</label>
                    </div>
                    <div className="col-sm-4">
                        {formatAmount(data.scheduledFee.amount)}
                    </div>
                    <div className="col-sm-2">
                        <label className="captionlabel">Convenience Fee</label>
                    </div>
                    <div className="col-sm-4">
                        {formatAmount(convenienceFee)}
                    </div>
                    <div className="col-sm-2">
                        <label className="captionlabel">Total Amount</label>
                    </div>
                    <div className="col-sm-4">
                        {formatAmount(data.scheduledFee.amount + convenienceFee)}
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-sm-4">
                        <button className="btn btn-primary" onClick={() => makePayment()}>Make Payment</button>
                    </div>
                </div>
            </div>
        </div>
    };

    return (
        <LoadingPage loading={loading} errorMessage={errorMessage}>
            <PageContent />
        </LoadingPage>
    );
}

export default Payment;