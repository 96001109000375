import AdmissionLogin from "./AdmissionLogin";
import StudentLogin from "./StudentLogin";
import AdminLogin from "./AdminLogin";
import { Routes, Route, Navigate } from "react-router-dom";
import NotFound from "../../NotFound";

function Index() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="student" />} />
      <Route path="admission" element={<AdmissionLogin />} />
      <Route path="student" element={<StudentLogin />} />
      <Route path="admin" element={<AdminLogin />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Index;
