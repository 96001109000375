import { Navigate, Routes, Route } from "react-router";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import Header from "../layouts/Header";
import NotFound from "../NotFound";
import Dashboard from "./Dashboard";
import Payment from "./Payment";
import PaymentResponse from "./PaymentResponse";
import Exam from "./Exam";
import ExamReport from "./ExamReport";

import { logOut } from "../../states/reducers/userReducer";
import { LoginType } from "../utils/Common";

function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const route = useLocation();
  const { paymentStatus, user } = useSelector((state) => state.user);

  useEffect(() => {
    if (!route.pathname.toLocaleLowerCase().startsWith("/admission/payment-response") && !paymentStatus.admissionPaymentPaid) {
      navigate(`payment?reference=${paymentStatus.admissionPaymentReference}`);
    }
    if (user.role !== LoginType.ADMISSION) {
      navigate('/');
    }
  }, [paymentStatus, route.pathname, navigate]);


  const logOutUser = () => {
    dispatch(logOut());
    navigate("/");
    //setLoggedOut(true);
  };
  return (
    <div className="maincontent">
      <Header />
      <hr />
      <div className="row d-print-none">
        <div className="col-sm-6 text-sm-left">
          <Link className="asterisk-danger" to="/auth/change-password">
            Change Password
          </Link>
        </div>
        <div className="col-sm-6 text-sm-end">
          <button
            onClick={() => logOutUser()}
            className="btn btn-link asterisk-danger text-decoration-none">
            Log Out
          </button>
        </div>
      </div>
      {/* {paymentLoaded && ( */}
      <Routes>
        <Route path="/" element={<Navigate to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="payment" element={<Payment />} />
        <Route path="payment-response" element={<PaymentResponse />} />
        <Route path="exam" element={<Exam />} />
        <Route path="result" element={<ExamReport />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* )} */}
    </div>
  );
}
export default Index;
