import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "@deltreetech/react-bootstrap-alert";
import Moment from "moment";
import { Modal, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import LoadingPage from "../layouts/LoadingPage";
import { addPaymentStatus } from "../../states/reducers/userReducer";
import { apiUrl, dateFormat, dateTimeFormat, capitalize, defaultContentFolder } from "../utils/Common";
import Header from "../layouts/Header";

const axios = require("axios");
const converter = require('number-to-words');

const NotificationToPrint = React.forwardRef((props, ref) => {
  const { account, admissionChoiceName, payment } = props;
  return (
    <div className="p-3" ref={ref}>
      <Header />
      <hr />
      <div className="row mt-1">
        <div className="col-sm-12 text-center h3 fw-bold">
          NOTIFICATION OF OFFER OF PROVISIONAL ADMISSION
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12">
          <span>Dear </span>
          <span className="fw-bold fw-bold">{`${account?.firstName} ${account?.surname} ${account?.othernames}`}</span>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-12">
          <p>
            This message is to notify you that you have been offered
            Provisional Admission to study {" "}
            <span className="fw-bold">{admissionChoiceName}</span>
          </p>
          <p>
            {`You are to pay an acceptance fee of ${capitalize(converter.toWords(payment?.admissionLetterPaymentAmount))} Naira only (`}<span>&#x20a6;{`${payment?.admissionLetterPaymentAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })}) to access your admission letter and payment details.`}</span>
          </p>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-sm-12 fw-bold">Account Details</div>
        <div className="col-sm-3">Account Name:</div>
        <div className="col-sm-9">
          ECWA College of Health Technology Kagoro
        </div>
        <div className="col-sm-3">Bank:</div>
        <div className="col-sm-9">First Bank</div>
        <div className="col-sm-3">Account Number:</div>
        <div className="col-sm-9">2000419824</div>
      </div>
      <div className="row mt-5">
        <div className="col-sm-12 fw-bold">PLEASE NOTE</div>
        <div className="col-sm-12">
          <ol style={{ listStyle: "lower-roman" }}>
            <li>
              All third party payment should contain the name of the
              candidate for whom the payment is made to facilitate easy
              reconciliation.
            </li>
            <li>USSD code payment is not acceptable.</li>
            <li>
              After payment, send scanned copy of the evidence of payment to
              the following WhatsApp Numbers{" "}
              <span className="fw-bold">
                08032264825; 09014129462; 07044410033
              </span>{" "}
              after which you will be allowed to print your letter
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
});

const AdmissionLetterToPrint = React.forwardRef((props, ref) => {
  const { account, admissionChoiceName, resumptionDate, imagePath, highestLevel, department } = props;
  const dateArray = resumptionDate.split("-");
  return (<div className="A4Page px-3" ref={ref}>
    <img src={`${defaultContentFolder}/images/oldheader.jpg`} alt="header" style={{ width: "100%" }} />
    <div className="ml-5">
      <div className="row">
        <div className="col-sm-9" style={{ fontSize: "120%" }}>
          <div className="row mt-5 ml-5">
            <div className="col-sm-12">
              <span>Dear </span>
              <span className="fw-bold fw-bold">{`${account?.firstName} ${account?.surname} ${account?.othernames}`}</span>
            </div>
          </div>
        </div>
        <div className="col-sm-2">
          <img className="passportimage" src={imagePath} alt="applicant passport" />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-sm-12 text-center">
          <div className="h2 fw-bold">
            OFFER OF PROVISIONAL ADMISSION <span>{account?.session}</span> SESSION
          </div>
        </div>
        <div className="col-sm-12">
          <p>I am pleased to inform you that you have been offered provisional admission into the college.</p>
        </div>
        <div className="col-sm-2">
          <label>Course:</label>
        </div>
        <div className="col-sm-10">
          <label className="fw-bold">{admissionChoiceName}</label>
        </div>
        <div className="col-sm-2">
          <label>Department:</label>
        </div>
        <div className="col-sm-10 fw-bold">
          {department}
        </div>
        <div className="col-sm-2">
          <label>Duration:</label>
        </div>
        <div className="col-sm-10 fw-bold">
          {`${capitalize(converter.toWords(highestLevel))} (${highestLevel}) years (${capitalize(converter.toWords(highestLevel * 2))} Semesters)`}
        </div>
        <div className="col-sm-2">
          <label>Resumption Date:</label>
        </div>
        <div className="col-sm-10 fw-bold">
          {Moment(new Date(dateArray[2], dateArray[1] - 1, dateArray[0])).format(dateFormat)}
        </div>
        <div className="col-sm-12 mt-3">
          <p>You may find that the programme offered to you is not what you applied for. This is because it was found necessary to make adjustments to your preference.</p>
          <p>Herewith is a general admission information sheet which contains schedules of fees to guide you.</p>
          <p>May the Lord provide and protect you as you come in Jesus Name</p>
          <p>Congratulations!</p>
        </div>
        <div className="col-sm-12 mt-5">
          <p>Yours in the Lord,</p>
          <img src={`${defaultContentFolder}/images/reg_signature_2022_small.jpg`} alt="Registrar Signature" className="img-fluid" />
        </div>
        <div className="col-sm-4" style={{ fontSize: "120%" }}>
          <p>
            <span className="fw-bold">Jude Makambong</span><br />
            Registrar<br />
            0704 441 0030
          </p>
        </div>
      </div>

      <div className="row d-print-none fw-bold">
        <a href={`${defaultContentFolder}/images/SFSFE.pdf`} target="_blank">Click Here to Download School Fees Schedule</a>
      </div>
    </div>
  </div>
  );
});

const RegistrationSlipPrint = React.forwardRef((props, ref) => {
  const { imagePath } = props;
  <>
    <section id="biodata" class="mt-4">
      <div class="row mx-3 fw-bold">
        <h2 class="col-sm-12 p-1 text-left">
          BiodataSummary
        </h2>
        <hr />
      </div>
      <div class="row ml-5">
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-4">
              <label class="fw-bold">Surname</label><br />
              <label id="SurnameLabel" /><br />
            </div>
            <div class="col-sm-4">
              <label class="fw-bold">First Name</label><br />
              <label id="FirstNameLabel" /><br />
            </div>
            <div class="col-sm-4">
              <label class="fw-bold">Other Names</label><br />
              <label id="OtherNameLabel" /><br />
            </div>
            <div class="col-sm-4">
              <label class="fw-bold">Marital Status</label><br />
              <label id="MaritalStatusLabel" /><br />
            </div>
            <div class="col-sm-4">
              <label class="fw-bold">Maiden Name</label><br />
              <label id="MaidenNameLabel" />
            </div>
            <div class="col-sm-4">
              <label class="fw-bold">Gender</label><br />
              <label id="GenderLabel" />
            </div>
            <div class="col-sm-4">
              <label class="fw-bold">Date of Birth</label><br />
              <label id="DOBLabel" />
            </div>
            <div class="col-sm-4">
              <label class="fw-bold">Place of Birth</label><br />
              <label id="BirthPlaceLabel" />
            </div>
            <div class="col-sm-4">
              <label class="fw-bold">Email</label><br />
              <label id="EmailLabel" />
            </div>
            <div class="col-sm-4">
              <label class="fw-bold">Mobile Number</label><br />
              <label id="MobileNumberLabel" />
            </div>
            <div class="col-sm-4">
              <label class="fw-bold">Disablity</label><br />
              <label id="DisabilityLabel" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <label class="fw-bold">Official Address</label><br />
              <label id="ContactAddressLabel" />
            </div>
            <div class="col-sm-6">
              <label class="fw-bold">Permanent Home Address</label><br />
              <label id="HomeAddressLabel" />
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <img className="passportimage" src={imagePath} alt="" />
        </div>
      </div>
    </section>
    <section id="coursechoice" class="mt-4">
      <div class="row mx-3 fw-bold ">
        <h2 class="col-sm-12 p-1 text-left">
          <label id="ChoiceSummaryLabel" Text="Course Choice" />
        </h2>
      </div>
      <div class="row ml-5">
        <div class="col-sm-2">
          <label id="FirstChoiceCaption" className="fw-bold">First Choice</label>
        </div>
        <div class="col-sm-4">
          <label id="FirstChoiceLabel" className="col-sm-12" />
        </div>
        <div class="col-sm-2">
          <label id="SecondChoiceCaption" className="fw-bold">Second Choice</label>
        </div>
        <div class="col-sm-4">
          <label id="SecondChoiceLabel" className="col-sm-12" />
        </div>
        <div class="col-sm-2">
          <label id="ThirdChoiceCaption" className="fw-bold">Third Choice</label>
        </div>
        <div class="col-sm-4">
          <label id="ThirdChoiceLabel" className="col-sm-12" />
        </div>
      </div>
    </section>
    <div id="MoreDetailsPanel" >
      <section id="education" class="mt-4">
        <div class="row mx-3 fw-bold ">
          <h2 class="col-sm-12 p-1 text-left">
            <label id="EducationSummaryLabel" Text="Education Section" />
          </h2>
        </div>
        <div style="width: 100%">
          <div style="margin: 0 auto; display: table">
            <table>
              <tr>
                <th>SN</th>
                <th>Type</th>
                <th>School</th>
                <th>Place & Country</th>
                <th>From Year</th>
                <th>To Year</th>
                <th>Certificate</th>
              </tr>
            </table>
          </div>
        </div>
      </section >
      <section id="examination" class="mt-4">
        <div class="row mx-3 fw-bold ">
          <h2 class="col-sm-12 p-1 text-left">
            <label>Examination Section</label>
          </h2>
        </div>
        <div style="width: 100%">
          <div style="margin: 0 auto; display: table">
            <table>
              <tr>
                <th>SN</th>
                <th>Exam Name</th>
                <th>Year</th>
                <th>Candidate/Center Number</th>
                <th>Center Address</th>
              </tr>
            </table>
          </div>
        </div>
      </section>
      <section id="olevel" class="mt-4">
        <div class="row mx-3 fw-bold ">
          <h2 class="col-sm-12 p-1 text-left">
            <label id="OLevelSummaryLabel" Text="O-Level Result" />
          </h2>
        </div>
        <div class="row ml-5">
          <div class="col-sm-5">
            <div class="row">
              <label id="FirstSittingLabel" Text="First Sitting" className="fw-bold" />
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label id="ExamNameCaptionLabel" Text="Exam Name" />
              </div>
              <div class="col-sm-6">
                <label id="ExamNameLabel" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label id="ExamYearCaptionLabel" Text="Exam Year" />
              </div>
              <div class="col-sm-6">
                <label id="ExamYearLabel" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label id="ExamNumberCaptionLabel" Text="Exam No" />
              </div>
              <div class="col-sm-6">
                <label id="ExamNumberLabel" />
              </div>
            </div>
            <div style="width: 100%">
              <div style="display: table">
                <table>
                  <tr>
                    <th>SN</th>
                    <th>Subject</th>
                    <th>Grade</th>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <div id="SummarySecondSittingPanel">
              <div class="row">
                <label id="SecondSittingLabel" Text="Second Sitting" className="fw-bold" />
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <label id="SecondExamNameCaptionLabel" Text="Exam Name" />
                </div>
                <div class="col-sm-6">
                  <label id="SecondExamNameLabel" />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <label id="SecondExamYearCaptionLabel" Text="Exam Year" />
                </div>
                <div class="col-sm-6">
                  <label id="SecondExamYearLabel" />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <label id="SecondExamNumberCaptionLabel" Text="Exam No" />
                </div>
                <div class="col-sm-6">
                  <label id="SecondExamNumberLabel" />
                </div>
              </div>
              <div style="width: 100%">
                <div style="display: table">
                  <table>
                    <tr>
                      <th>SN</th>
                      <th>Subject</th>
                      <th>Grade</th>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="reference" class="mt-4">
        <div class="row mx-3 fw-bold ">
          <h2 class="col-sm-12 p-1 text-left">
            <label id="ReferenceSummaryLabel">Reference</label>
          </h2>
        </div>
        <div class="row ml-5">
          <div class="col-sm-3">
            <label class="fw-bold">Reference Name</label>
            <br />
            <label id="FirstRefreeNameLabel" />
          </div>
          <div class="col-sm-3">
            <label class="fw-bold">Mobile Number</label>
            <br />
            <label id="FirstRefreeNumberLabel" />
          </div>
          <div class="col-sm-3">
            <label class="fw-bold">Email</label>
            <br />
            <label id="FirstRefreeEmailLabel" />
          </div>
          <div class="col-sm-3">
            <label class="fw-bold">Address</label>
            <br />
            <label id="FirstRefreeAddressLabel" />
          </div>
        </div>
      </section>
    </div >
  </>;
});

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [examModal, setExamModal] = useState({
    show: false,
    loadingInfo: false,
    loadingResult: false,
  });
  const [admissionNotificationModal, setAdmissionNotificationModal] = useState(false);
  const [admissionLetterModal, setAdmissionLetterModal] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    account: null,
    payment: null,
  });
  const { user } = useSelector(state => state.user);
  const componentRef = useRef(null);
  useEffect(() => {
    axios
      .get(`${apiUrl}/admission/dashboard`, {
        headers: {
          Authorization: `Bearer ${user.jwtToken}`,
        },
      })
      .then(response => {
        const { success, data, message } = response.data;
        if (success) {
          setDashboardData(data);
          dispatch(addPaymentStatus(data.payment));
        } else {
          setErrorMessage(message);
        }
        setLoading(false);
      })
      .catch(error => {
        setErrorMessage("Error Fetching Dashboard Data!");
        setLoading(false);
      });
  }, [user, dispatch]);
  const handleModalPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const showAdmissionOrExamButton = () => {
    if (
      dashboardData?.account &&
      dashboardData.account.admissionChoice &&
      dashboardData.account.admissionChoice !== ""
    ) {
      if (dashboardData?.payment?.admissionLetterPaymentPaid) {
        return (
          <>
            <div className="col-md-2">
              <button className="btn btn-success" onClick={() => setAdmissionLetterModal(true)}>Admission Letter</button>
            </div>
          </>
        );
      }
    } else {
      return (
        dashboardData?.attemptReady && (
          <div className="col-md-2">
            {dashboardData?.satForExam ? (
              <button className="btn btn-success" onClick={() => showResult()}>
                {examModal.loadingResult ? (
                  <span>
                    <Spinner animation="border" size="sm" variant="white" />{" "}
                    Loading Result . . .
                  </span>
                ) : (
                  <span>View Result</span>
                )}
              </button>
            ) : (
              <button
                className="btn btn-success"
                onClick={() => loadExamInfo()}>
                {examModal.loadingInfo ? (
                  <span>
                      <Spinner animation="border" size="sm" variant="white" />{" "}
                      Take Exam . . .
                    </span>
                  ) : (
                    <span>Take Exam</span>
                  )}
              </button>
            )}
          </div>
        )
      );
    }
  };

  const showAdmissionNotification = () => {
    if (
      dashboardData?.account &&
      dashboardData.account.admissionChoice &&
      dashboardData.account.admissionChoice !== ""
    ) {
      if (dashboardData?.payment?.admissionLetterPaymentPaid) {
        return (
          <>
            <div className="alert alert-success" role="alert">
              You have been offered <span className='fw-bold'>Provisional Admission.</span> Please Click on Admission Letter button below to print
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="alert alert-success" role="alert">
              You have been offered <span className='fw-bold'>Provisional Admission.</span> Please make payment to print your admission letter. <button className='btn btn-link alert-link fw-bold' onClick={() => setAdmissionNotificationModal(true)}>CLICK HERE TO GET PAYMENT INFORMATION</button>
            </div >
          </>
        );
      }
    }
  }

  const loadExamInfo = () => {
    if (dashboardData.examInfo) {
      setExamModal({ ...examModal, loadingInfo: false, show: true });
      return;
    }
    setExamModal({ ...examModal, loadingInfo: true });
    axios
      .get(`${apiUrl}/admission/exam/info`, {
        headers: {
          Authorization: `Bearer ${user.jwtToken}`,
        },
      })
      .then(response => {
        const { success, data, message } = response.data;
        if (success) {
          setDashboardData({ ...dashboardData, examInfo: data });
          setExamModal({ ...examModal, loadingInfo: false, show: true });
        } else {
          toast.error(message);
          setExamModal({ ...examModal, loadingInfo: false });
        }
      })
      .catch(() => {
        toast.error("There is an error loading Exam Information");
        setExamModal({ ...examModal, loadingInfo: false });
      });
  };

  const showResult = () => {
    setExamModal({ ...examModal, loadingResult: true });
    axios
      .get(`${apiUrl}/admission/exam/result`, {
        headers: {
          Authorization: `Bearer ${user.jwtToken}`,
        },
      })
      .then(response => {
        const { success, data, message } = response.data;
        if (success) {
          navigate("../result", {
            state: {
              satForExam: data.submitted,
              result: data.result,
            },
          });
        } else {
          toast.error(message);
          setExamModal({ ...examModal, loadingResult: false });
        }
      })
      .catch(() => {
        toast.error("There is an error loading Result");
        setExamModal({ ...examModal, loadingResult: false });
      });
  };

  const PageContent = () => {
    const { account, imagePath, payment, admissionChoiceName } = dashboardData;
    return (
      <>
        <Alert id="alert-container" />
        {showAdmissionNotification()}
        <div className="row ms-5">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-3">
                <label className="fw-bold">Registration Number</label>
              </div>
              <div className="col-sm-6">
                <label>{account?.entranceID}</label>
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <label className="fw-bold">Applicant Names</label>
              </div>
              <div className="col-sm-6">
                <label>{`${account?.firstName} ${account?.surname} ${account?.othernames}`}</label>
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <label className="fw-bold">Gender</label>
              </div>
              <div className="col-sm-6">{account?.gender}</div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <label className="fw-bold">Date of Birth</label>
              </div>
              <div className="col-sm-6">
                {account?.dob == null ? "NIL" : Moment(account?.dob).format(dateFormat)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <label className="fw-bold">Exam Session</label>
              </div>
              <div className="col-sm-6">{account?.session}</div>
            </div>
          </div>
          <div className="col-sm-2">
            <img className="passportimage" src={imagePath} alt="" />
          </div>
        </div>
        <div className="row justify-content-sm-between mb-3 mt-5 mr-3">
          <div className="col-md-2">
            <button className="btn btn-primary">Print Registration Slip</button>
          </div>
          {showAdmissionOrExamButton()}
        </div>
        <Modal
          backdrop="static"
          keyboard={false}
          show={examModal.show}
          onHide={() => setExamModal({ ...examModal, show: false })}
          size="lg"
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2 className="mt-2">
              <span>Exam Details</span>
            </h2>
            <div className="row">
              <div className="col-sm-3">
                <label className="fw-bold">Instruction</label>
              </div>
              <div className="col-sm-9">
                {dashboardData?.examInfo?.instruction}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <label className="fw-bold">Exam Date</label>
              </div>
              <div className="col-sm-9">
                {`${Moment(dashboardData?.examInfo?.startDate).format(
                  dateTimeFormat
                )} to ${Moment(dashboardData?.examInfo?.endDate).format(
                  dateTimeFormat
                )}`}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-3">
                <label className="fw-bold">Duration</label>
              </div>
              <div className="col-sm-3">
                {`${dashboardData?.examInfo?.duration} minutes`}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-3">
                <label className="fw-bold">Total Question</label>
              </div>
              <div className="col-sm-3">
                {dashboardData?.examInfo?.totalQuestions}
              </div>
            </div>
            <div className="row justify-content-center px-1">
              <label id="ExamModalMessageLabel" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setExamModal({ ...examModal, show: false })}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => navigate("../exam")}>
              Start Exam
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          backdrop="static"
          id="NotificationModal"
          keyboard={false}
          show={admissionNotificationModal}
          onHide={() =>
            setAdmissionNotificationModal(false)
          }
          size="lg"
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Admission Letter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NotificationToPrint
              account={account}
              admissionChoiceName={admissionChoiceName}
              payment={payment}
              ref={componentRef}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() =>
                setAdmissionNotificationModal(false)
              }>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => handleModalPrint()}>
              Print
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          backdrop="static"
          id="AdmissionLetterModal"
          keyboard={false}
          show={admissionLetterModal}
          onHide={() =>
            setAdmissionLetterModal(false)
          }
          fullscreen={true}
          size="lg"
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Admission Letter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AdmissionLetterToPrint
              {...dashboardData}
              ref={componentRef}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() =>
                setAdmissionLetterModal(false)
              }>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => handleModalPrint()}>
              Print
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  return (
    <LoadingPage loading={loading} errorMessage={errorMessage}>
      <PageContent />
    </LoadingPage>
  );
}

export default Dashboard;
