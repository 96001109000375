import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import { MathJax } from 'better-react-mathjax';

import LoadingPage from '../layouts/LoadingPage';
import {
    addAnswers,
    setLastSyncTime,
    setRemainingDurationInSeconds,
    setAll, removeState
} from "../../states/reducers/examReducer";
import Timer from '../Timer';
import { apiUrl } from "../utils/Common";

const axios = require("axios");

function Exam() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [examProp] = useState(state);

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [submitModal, setSubmitModal] = useState({
        show: false,
        submitting: false
    });
    let duration = 0;
    const { user } = useSelector((state) => state.user);
    const { questions, answers, remainingDurationInSeconds, examId } = useSelector((state) => state.exam);
    useEffect(() => {
        if (questions.length > 0) {
            setLoading(false);
        }
        if (examProp == null || (examProp.examIdProp ?? examId) == null) navigate("../dashboard");
        axios
            .get(`${apiUrl}/student/exam/${examProp.examIdProp ?? examId}${questions.length > 0 ? '?onlyTime=true' : ''}`, {
                headers: {
                    Authorization: `Bearer ${user.jwtToken}`,
                },
            })
            .then((response) => {
                const { success, data, message } = response.data;
                if (success) {
                    if (data.satForExam) {
                        navigateToReport(true, data.result);
                        return;
                    }
                    if (questions.length > 0) {
                        duration = data.remainingDurationInSeconds;
                        dispatch(setRemainingDurationInSeconds(data.remainingDurationInSeconds));
                    } else {
                        dispatch(setAll({ ...data, lastSyncTime: Date.now }));
                        setLoading(false);
                    }
                } else {
                    setErrorMessage(message);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error Loading  Please reload the page", error);
                setLoading(false);
            });
    }, [user.jwtToken, dispatch]);

    const saveExam = (index, answer = null, submit = false) => {
        const newAnswers = [...answers];
        if (answer)
            newAnswers[index] = answer.trim();
        dispatch(addAnswers(newAnswers));
        const postData = {
            examId: examId,
            answers: newAnswers,
            remainingDurationInSeconds: duration,
            submit: submit
        };
        dispatch(setRemainingDurationInSeconds(duration));
        submit && setSubmitModal({ ...submitModal, submitting: true });
        axios
            .post(`${apiUrl}/student/exam/save`, postData, {
                headers: {
                    Authorization: `Bearer ${user.jwtToken}`,
                },
            }).then((response) => {
                const { success, data, message } = response.data;
                if (success) {
                    if (!data.submitted) {
                        setLastSyncTime(Date.now);
                        dispatch(setRemainingDurationInSeconds(duration));
                    } else {
                        dispatch(removeState());
                        navigateToReport(data.submitted, data.result);
                    }
                } else {
                    setLoading(false);
                    console.log(message);
                    dispatch(setRemainingDurationInSeconds(duration));
                }
            })
            .catch((error) => {
                console.error("Error Loading. Please reload the page", error);
                setLoading(false);
                dispatch(setRemainingDurationInSeconds(duration));
            });
    };

    const navigateToReport = (submitted, result) => {
        navigate("../result", {
            state:
            {
                satForExam: submitted,
                result: result
            }
        });
    };
    const showExamModal = () => {
        setSubmitModal({ ...submitModal, show: true });
        dispatch(setRemainingDurationInSeconds(duration));
    };
    const closeExamModal = () => {
        setSubmitModal({ ...submitModal, show: false });
        dispatch(setRemainingDurationInSeconds(duration));
    };

    const timer = (durationInSeconds) => {
        duration = durationInSeconds;
        if (durationInSeconds === 0) {
            saveExam(0, null, true);
        }
    };

    const formatDisplay = (data) => {
        return data.replace("/StaticContent/", "https://portal.echtkagoro.edu.ng/StaticContent/");
    }
    const PageContent = () => {
        return examId && (
            <>
                <div className="row mb-1 py-1 justify-content-end">
                    <div className="col-sm-4 text-center position-fixed">
                        <Timer durationInSeconds={remainingDurationInSeconds} timer={timer} />
                        <div className="col-sm-12 mt-2">
                            <button type="button" className="btn btn-primary" onClick={() => showExamModal()}>
                                {submitModal.submitting ? (
                                    <span>
                                        <Spinner animation="border" size="sm" variant="white" /> Submitting . . .
                                    </span>
                                ) : (
                                    <span>Submit Exam</span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-8">
                        {questions?.map((q, index) => {
                            return <div key={q.questionId} className="row p-3">
                                <div className="col-sm-12">
                                    {q.rangeInfo && <div className='mb-2 ckcontent' dangerouslySetInnerHTML={{ __html: formatDisplay(q.rangeInfo) }} />}
                                    <div className='input-group mb-2'>
                                        <label className="fw-bold me-2">{`${index + 1}.`}</label>
                                        <MathJax>
                                            <label className="fw-bold ckcontent" dangerouslySetInnerHTML={{ __html: formatDisplay(q.question) }} />
                                        </MathJax>
                                    </div>
                                    <div className="optionGroup">
                                        {q.options?.map((o, i) => {
                                            return <div key={`${q.questionId}${o.optionId}`} className="input-group mb-2">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">
                                                        <label className='fw-bold me-2'>{o.optionId}</label>
                                                        <input type="radio" name={q.questionId} checked={answers[index].trim() === o.optionId.trim()} onChange={(e) => saveExam(index, o.optionId)} />
                                                    </div>
                                                </div>
                                                <MathJax>
                                                    <label className="ckcontent ps-2" dangerouslySetInnerHTML={{ __html: formatDisplay(o.option.trim()) }} />
                                                </MathJax>
                                            </div>;
                                        })}
                                    </div>
                                </div>
                            </div>;
                        })}
                    </div>
                    <div className="col-sm-4">
                        <div className="row py-2 mb-5 ms-3">
                            <div className="col-sm-12">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-5 justify-content-center">
                    <div className="col-sm-2">
                        <button type="button" className="btn btn-primary" onClick={() => showExamModal()}>
                            {submitModal.submitting ? (
                                <span>
                                    <Spinner animation="border" size="sm" variant="white" /> Submitting . . .
                                </span>
                            ) : (
                                <span>Submit Exam</span>
                            )}
                        </button>
                    </div>
                </div>

                {/* Confirmation Modal */}
                <Modal backdrop="static"
                    keyboard={false}
                    show={submitModal.show}
                    onHide={() => closeExamModal()}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>This will end your Exam Are you sure you want to finally Submit?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeExamModal()}>
                            No
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => saveExam(0, null, true)}
                            disabled={submitModal.submitting}>
                            {submitModal.submitting ? (
                                <span>
                                    <Spinner animation="border" size="sm" variant="white" /> Submitting Exam . . .
                                </span>
                            ) : (
                                <span>Yes</span>
                            )}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>);
    };

    return (
        <LoadingPage loading={loading} errorMessage={errorMessage}>
            <PageContent />
        </LoadingPage>
    );
}

export default Exam;