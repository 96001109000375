import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isFetching: false,
    user: {},
    paymentStatus: {
        admissionPaymentReference: "",
        admissionPaymentPaid: false,
        admissionLetterPaymentPaid: false
    }
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setIsFetching: (state) => { state.isFetching = true; },
        addUser: (state, action) => { state.user = action.payload },
        addPaymentStatus: (state, action) => { state.paymentStatus = action.payload; },
        logOut: (state) => { state = initialState; }
    }
});

export const { setIsFetching, addUser, addPaymentStatus, logOut } = userSlice.actions;

export default userSlice.reducer;