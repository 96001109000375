import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function ExamReport() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [report] = useState(state);
    useEffect(() => {
        if (report == null || !report.satForExam) navigate("../dashboard");
    }, [navigate]);

    return (
        <>
            {
                (report && report.result && report.result.correctAnswers ?
                    <>
                        <div className="row">
                            <div className="col-sm-2">
                                <label className="captionlabel">Total Questions</label>
                            </div>
                            <div className="col-sm-4">
                                {report.result.totalQuestions}
                            </div>
                            <div className="col-sm-2">
                                <label className="captionlabel">Total Right Answers</label>
                            </div>
                            <div className="col-sm-4">
                                {report.result.correctAnswers}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2">
                                <label className="captionlabel">Total Wrong Answers</label>
                            </div>
                            <div className="col-sm-4">
                                {report.result.wrongAnswers}
                            </div>
                            <div className="col-sm-2">
                                <label className="captionlabel">Total Percentage Score</label>
                            </div>
                            <div className="col-sm-4">
                                {`${report.result.percentageScore}%`}
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="alert alert-success fade show text-center" role="alert">
                            <div className='fw-bold' style={{ fontSize: "1.5em" }}>
                                <p>Your examination is completed successfully.</p>
                                <p> Your result will soon be out</p>
                            </div>
                        </div>
                    </>
                )
            }
            <div className='text-center mt-3' style={{ fontSize: "1.5em" }}>
                <Link to="../dashboard" >Goto Dashboard</Link>
            </div>
        </>
    );
}

export default ExamReport;