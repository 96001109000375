const formatFileName = (filename) => {
  //filename.replace(#, [, ], *, or ?)
  return filename;
};

const hasErrorClass = (error, useDefaultClass = true) => {
  return `${useDefaultClass ? "form-control" : ""} ${error ? " is-invalid" : ""}`;
};

const loginHasErrorClass = (error, useDefaultClass = true) => {
  return `${useDefaultClass ? "shadow-none" : ""} ${error ? " is-invalid" : ""}`;
};

const AdvertStatusType = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  ACTIVE: "ACTIVE",
  EXPIRED: "EXPIRED",
};

const PaymentType = {
  Tuition: "Tuition",
  LateFee: "LateFee",
  Entrance: "Entrance",
  AdmissionLetter: "AdmissionLetter",
  Wallet: "Wallet",
  ToWallet: "ToWallet"
}

const PaymentStatusType = {
  success: "success",
  failed: "failed"
}

const LoginType = {
  ADMISSION: "ADMISSION",
  ADMIN: "ADMIN",
  STUDENT: "STUDENT",
};

const formatAmount = (amount) => {
  return (
    <span>
      &#x20a6;
      {parseFloat(amount)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    </span>
  );
};

const formatDays = (periodInDays) => {
  const mod30 = periodInDays % 30;
  const quantity = mod30 === 0 ? periodInDays / 30 : periodInDays / 7;
  return quantity + (mod30 === 0 ? " month" : " week") + (quantity > 1 ? "s" : "");
};

const capitalize = (sentence) => {
  return sentence.toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const resizeImage = (url) => {
  const splitter = "upload/";
  const imageUrls = url.split(splitter);
  return imageUrls[0] + splitter + "h_100,c_scale/" + imageUrls[1];
};

const PostType = [
  { name: "ASSIGNMENT/CA", value: "QUIZ" }, { name: "EXAM", value: "EXAM" }
];

const convenienceFee = 300;
const paystackPublicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
const apiUrl = process.env.REACT_APP_API_URL;

const dateTimeFormat = "DD MMM yyyy hh:mm A";
const dateTimeFormatShort = "DD/MM/YY hh:mm A";

const dateFormat = "DD MMMM, yyyy";
const dateFormatShort = "DD/MM/YY";
const defaultContentFolder = "https://portal.echtkagoro.edu.ng/StaticContent";

export {
  formatFileName,
  apiUrl,
  hasErrorClass,
  loginHasErrorClass,
  AdvertStatusType,
  formatAmount,
  formatDays,
  resizeImage,
  capitalize,
  PaymentType,
  PaymentStatusType,
  LoginType,
  PostType,
  convenienceFee,
  paystackPublicKey,
  dateFormat,
  dateFormatShort,
  dateTimeFormat,
  dateTimeFormatShort,
  defaultContentFolder
};
