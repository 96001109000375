import { Routes, Route, Navigate } from "react-router-dom";
import Header from "../layouts/Header";
import NotFound from "../NotFound";
import ForgotPassword from "./ForgotPassword";
import ChangePassword from "./ChangePassword";
import LoginIndex from "./login/Index";
import Register from "./Register";

function Index() {
  // if (localStorage.getItem("user")) {
  //   return <Navigate to="/admission" replace />;
  // }
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Navigate to="login" />} />
        <Route path="login/*" element={<LoginIndex />} />
        <Route path="register" element={<Register />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default Index;
